import React, { Suspense } from 'react'
import Web3ReactManager from '../components/Web3ReactManager'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Layout, Modal } from 'antd'
import { useMedia } from 'react-use'
import SiderBar from '../components/SiderBar'
import Overview from './Overview'
import Pairs from './Pairs'
import Exchange from './Exchange'
import Farms from './Farms'
import Staking from './Staking'
// import Investment from './Investment'
import InvestmentLending from './InvestmentLending'
import Savault from './Savault'
import Farm from './Farm'
// import Lending from './Lending'
import NewLending from './Lending/NewLending'
// import About from './About'
import Popups from '../components/Popups'
import Distribution from './Distribution'
import Bridge from './Bridge'
import TokenPage from './Tokens/TokenPage'
import { OVERVIEW_TOKEN_BLACKLIST } from '../constants'
import { isAddress } from 'ethers/lib/utils'

const { Content, Sider } = Layout

const App: React.FC = () => {
  // const below576 = useMedia('(max-width:576px)')
  const below576 = useMedia('(max-width:768px)')
  console.log(below576, ':below576')

  Modal.info({
    title: 'Alert !!!!!!',
    content:
      'Due to the technical issues, we are currently suspend functions of adding any liquidity and trading!! This is a protection from financial loss !!!! We are sorry about the inconvenience!!'
  })

  return (
    <Suspense fallback={null}>
      <Popups />
      <Web3ReactManager>
        <Router>
          <Distribution />
          <Layout style={{ minHeight: '100vh' }}>
            {!below576 && (
              <Sider
                width={320}
                style={{
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  left: 0,
                  zIndex: 1
                }}
              >
                <SiderBar />
              </Sider>
            )}
            <Layout style={{ marginLeft: !below576 ? 320 : 0 }}>
              {below576 && <SiderBar />}
              <Content>
                <Switch>
                  <Route exact path="/">
                    <Overview />
                  </Route>
                  <Route path="/home">
                    <Overview />
                  </Route>
                  <Route path="/pairs">
                    <Pairs />
                  </Route>
                  <Route
                    exact
                    strict
                    path="/token/:tokenAddress"
                    render={({ match }) => {
                      if (OVERVIEW_TOKEN_BLACKLIST.includes(match.params.tokenAddress.toLowerCase())) {
                        return <Redirect to="/home" />
                      }
                      if (isAddress(match.params.tokenAddress.toLowerCase())) {
                        return <TokenPage />
                      } else {
                        return <Redirect to="/pairs" />
                      }
                    }}
                  />
                  <Route path="/exchange">
                    <Exchange />
                  </Route>
                  <Route path="/farms">
                    <Farms />
                  </Route>
                  <Route path="/staking">
                    <Staking />
                  </Route>
                  <Route path="/investment">
                    <InvestmentLending />
                  </Route>
                  <Route path="/lending">
                    <NewLending />
                  </Route>
                  {/* <Route path="/new-lending">
                    <Lending />
                  </Route> */}
                  <Route path="/savault">
                    <Savault />
                  </Route>
                  <Route path="/farm/:farmName/:farmToken/:poolId">
                    <Farm />
                  </Route>
                  <Route path="/bridge">
                    <Bridge />
                  </Route>
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Router>
      </Web3ReactManager>
    </Suspense>
  )
}

export default App
