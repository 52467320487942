import axios from 'axios'
import qs from 'qs'
const { NODE_ENV } = process.env
const server = axios.create({
  baseURL: NODE_ENV === 'development' ? '/' : 'https://sashimi.cool',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  timeout: 50000,
  transformRequest: function(data) {
    if (typeof data === 'object') {
      return qs.stringify(data)
    }
    return data
  }
})
// TODO:请求拦截处理
server.interceptors.request.use(config => {
  return config
})

// TODO:返回拦截处理
server.interceptors.response.use(res => {
  return res
})

export default server
